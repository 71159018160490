<template>
  <!-- Solo quando `isMounted` è true mostriamo la transizione -->
  <transition name="fade" v-if="isMounted">
    <div class="row row-eq-height mt-5 gap-4 gap-lg-0" v-if="!isDismissed">
      <div class="alert alert-info d-flex  gap-4 align-items-center mb-0" role="alert">
        <div class="avatar-circle">
          <img src="@/assets/images/icons/AlertCircle.svg" alt="" />
        </div>
        <div class="text">
          <strong>Nuovo corso in arrivo sulla piattaforma</strong>
          <p class="mb-0 mt-2">

            Scopri il nostro ultimo corso: <b>"Javascript"</b> e tieni d'occhio la sezione "<a href="#"
              @click.prevent="notifyParent" class="link-info">Corsi in arrivo</a>" per rimanere aggiornato su tutti i
            corsi firmati Easycode.

          </p>
        </div>
        <button type="button" class="btn-close custom-close" aria-label="Close" @click="dismissNotification"></button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, onMounted, defineEmits } from "vue";

const emits = defineEmits(["navigate-to-course"]); // Definiamo l'evento

const isDismissed = ref(false);
const isMounted = ref(false); // Controlla se il componente è montato

// ID della notifica attuale
const currentNotificationId = 1;

// Funzione per recuperare gli ID delle notifiche chiuse
const getDismissedNotifications = () => {
  const storedData = localStorage.getItem("dismissedNotifications");
  return storedData ? JSON.parse(storedData) : [];
};

// Funzione per salvare gli ID delle notifiche chiuse
const setDismissedNotifications = (ids) => {
  localStorage.setItem("dismissedNotifications", JSON.stringify(ids));
};

// Funzione per chiudere la notifica
const dismissNotification = () => {
  const dismissedNotifications = getDismissedNotifications();
  dismissedNotifications.push(currentNotificationId); // Aggiunge l'ID corrente
  setDismissedNotifications(dismissedNotifications);
  isDismissed.value = true; // Avvia la transizione per chiudere
};

// Funzione per notificare il genitore (navigazione al corso)
const notifyParent = () => {
  emits("navigate-to-course"); // Emette l'evento al padre
};

// Controllo iniziale al montaggio del componente
onMounted(() => {
  const dismissedNotifications = getDismissedNotifications();
  // Nasconde il componente se l'ID corrente è già stato notificato
  if (dismissedNotifications.includes(currentNotificationId)) {
    isDismissed.value = true;
  }
  isMounted.value = true; // Attiva la transizione solo dopo il mount
});
</script>

<style scoped>
.alert-info {
  border: 1px solid #49a8ee;
}

.alert {
  background-color: #eaf6fd;
  color: #000;
  border-left: 8px solid #2372A4;
  padding: 1rem;
  padding-right: 2rem;
  border-radius: 8px;
}



.custom-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.8rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 16px;
  /* line-height: 1.2; */
}

p {
  margin: 0;
}

.link-info {
  color: #000 !important;
  /* font-weight: 600; */
  text-decoration: underline;
  text-decoration-color: #000 !important;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

/* Stile della transizione */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.avatar-circle {
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.2rem;
  color: #242424;

  img {
    width: 16px;
    height: 16px;
  }
}
</style>
